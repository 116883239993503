import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { PluginConfigRoot } from '@wix/comments-ooi-client';

import {
  IMAGE_TYPE,
  pluginImage,
  GIPHY_TYPE,
  pluginGiphy,
  pluginEmojiViewer,
  EMOJI_TYPE,
  pluginEmoji,
  MENTION_TYPE,
  pluginMentions,
  LINK_TYPE,
  pluginLink,
  HTML_TYPE,
  pluginHtml,
  FILE_UPLOAD_TYPE,
  pluginFileUpload,
  pluginVideo,
  VIDEO_TYPE,
  videoButtonsTypes,
  pluginAudio,
  AUDIO_TYPE,
  audioButtonsTypes,
  LINK_BUTTON_TYPE,
  pluginLinkButton,
  pluginVerticalEmbed,
  VERTICAL_EMBED_TYPE,
  verticalEmbedProviders,
  LINK_PREVIEW_TYPE,
  LinkPreviewProviders,
  pluginLinkPreview,
  pluginSpoiler,
  SPOILER_TYPE,
  SpoilerEditorWrapper,
  initSpoilersContentState,
  pluginSpoilerViewer,
  SpoilerViewerWrapper,
  pluginTextColor,
  pluginTextHighlight,
  TEXT_COLOR_TYPE,
  TEXT_HIGHLIGHT_TYPE,
  pluginTextColorViewer,
  pluginTextHighlightViewer,
  HEADINGS_DROPDOWN_TYPE,
  pluginHeadings,
  LINE_SPACING_TYPE,
  pluginLineSpacing,
  pluginLineSpacingViewer,
} from '@wix/ricos';

import {
  pluginImageLoadable as pluginImageViewer,
  pluginGiphyLoadable as pluginGiphyViewer,
  pluginMentionsLoadable as pluginMentionsViewer,
  pluginLinkLoadable as pluginLinkViewer,
  pluginHtmlLoadable as pluginHtmlViewer,
  pluginFileUploadLoadable as pluginFileUploadViewer,
  pluginVideoLoadable as pluginVideoViewer,
  pluginAudioLoadable as pluginAudioViewer,
  pluginLinkButtonLoadable as pluginLinkButtonViewer,
  pluginVerticalEmbedLoadable as pluginVerticalEmbedViewer,
  pluginLinkPreviewLoadable as pluginLinkPreviewViewer,
} from '@wix/ricos/loadable';

import '@wix/ricos/css/plugin-spoiler-viewer.global.css';

import { useMentions } from 'common/components/Ricos/hooks/useMentions';

import { selectIsSiteAdmin } from 'store/selectors';
import { useController } from 'common/context/controller/index';

export function useCommentsPluginConfig(groupId: string) {
  const mentionsHelpers = useMentions(groupId, true);
  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const { verticals$ } = useController();
  const { hasEventsInstalled, hasStoresInstalled, hasBookingsInstalled } =
    verticals$;

  // we add vertical plugin only if any of vertical is installed and only if current user is site owner
  const showEmbedPlugins =
    isSiteAdmin &&
    (hasEventsInstalled || hasStoresInstalled || hasBookingsInstalled);

  return useMemo<PluginConfigRoot>(() => {
    const plugins: PluginConfigRoot = {
      [IMAGE_TYPE]: {
        editorModule: pluginImage,
        viewerModule: pluginImageViewer,
      },
      [EMOJI_TYPE]: {
        editorModule: pluginEmoji,
        viewerModule: pluginEmojiViewer,
      },
      [VIDEO_TYPE]: {
        editorModule: pluginVideo,
        editorConfig: { exposeButtons: [videoButtonsTypes.video] },
        viewerModule: pluginVideoViewer,
        viewerConfig: { exposeButtons: [videoButtonsTypes.video] },
      },
      [AUDIO_TYPE]: {
        editorModule: pluginAudio,
        editorConfig: { exposeButtons: [audioButtonsTypes.soundCloud] },
        viewerModule: pluginAudioViewer,
        viewerConfig: { exposeButtons: [audioButtonsTypes.soundCloud] },
      },
      [GIPHY_TYPE]: {
        editorModule: pluginGiphy,
        viewerModule: pluginGiphyViewer,
      },
      [FILE_UPLOAD_TYPE]: {
        editorModule: pluginFileUpload,
        viewerModule: pluginFileUploadViewer,
      },
      [LINK_TYPE]: { editorModule: pluginLink, viewerModule: pluginLinkViewer },
      [MENTION_TYPE]: {
        editorModule: pluginMentions,
        editorConfig: mentionsHelpers,
        viewerModule: pluginMentionsViewer,
        viewerConfig: mentionsHelpers,
      },
      [LINK_PREVIEW_TYPE]: {
        editorModule: pluginLinkPreview,
        editorConfig: {
          exposeEmbedButtons: [
            LinkPreviewProviders.Twitter,
            LinkPreviewProviders.TikTok,
            'Facebook',
            LinkPreviewProviders.Instagram,
          ],
        },
        viewerModule: pluginLinkPreviewViewer,
        viewerConfig: {
          exposeEmbedButtons: [
            LinkPreviewProviders.Twitter,
            LinkPreviewProviders.TikTok,
            'Facebook',
            LinkPreviewProviders.Instagram,
          ],
        },
      },
      [LINK_BUTTON_TYPE]: {
        editorModule: pluginLinkButton,
        viewerModule: pluginLinkButtonViewer,
      },
      ...(showEmbedPlugins && {
        [VERTICAL_EMBED_TYPE]: {
          editorModule: pluginVerticalEmbed,
          editorConfig: {
            exposeEmbedButtons: [
              ...(hasEventsInstalled ? [verticalEmbedProviders.event] : []),
              ...(hasBookingsInstalled ? [verticalEmbedProviders.booking] : []),
              ...(hasStoresInstalled ? [verticalEmbedProviders.product] : []),
            ],
          },
          viewerModule: pluginVerticalEmbedViewer,
          viewerConfig: {
            exposeEmbedButtons: [
              ...(hasEventsInstalled ? [verticalEmbedProviders.event] : []),
              ...(hasBookingsInstalled ? [verticalEmbedProviders.booking] : []),
              ...(hasStoresInstalled ? [verticalEmbedProviders.product] : []),
            ],
          },
        },
      }),
      [SPOILER_TYPE]: {
        editorModule: pluginSpoiler,
        editorConfig: {
          SpoilerEditorWrapper,
        },
        viewerModule: pluginSpoilerViewer,
        viewerConfig: {
          SpoilerViewerWrapper,
          initSpoilersContentState,
        },
      },
      [LINE_SPACING_TYPE]: {
        editorModule: pluginLineSpacing,
        viewerModule: pluginLineSpacingViewer,
      },
      [TEXT_COLOR_TYPE]: {
        editorModule: pluginTextColor,
        viewerModule: pluginTextColorViewer,
      },
      [TEXT_HIGHLIGHT_TYPE]: {
        editorModule: pluginTextHighlight,
        viewerModule: pluginTextHighlightViewer,
      },
      [HEADINGS_DROPDOWN_TYPE]: {
        editorModule: pluginHeadings,
        viewerModule: pluginHeadings,
      },
      [HTML_TYPE]: {
        editorModule: isSiteAdmin
          ? pluginHtml
          : () => {
              return { type: '', config: {} };
            },
        viewerModule: pluginHtmlViewer,
      },
    };
    return plugins;
  }, [groupId, isSiteAdmin]);
}
